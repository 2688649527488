import { createBrokerHelper, UserEventType } from '@flock/utils'

import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Modal, Typography, useTheme } from '@mui/material'
import {
  AnyInputConfig,
  CloseIcon,
  GridForm,
  InputType,
  TrackedIconButton,
  useTracking,
} from '@flock/shared-ui'
import {
  LandingCreateBrokerDocument,
  LandingSearchBrokersDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

type FormData = {
  fullName: string
  email: string
  phoneNumber: string
}

type AgentContactFormProps = {
  open: boolean
  onClose: () => void
  exampleOfferPageUrl?: string
}

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'prompt',
    type: InputType.CustomComponent,
    props: {
      component: (
        <Typography variant="h3" pb="16px" pt="32px">
          Let us know your contact info and we&apos;ll set up an example offer
          page for you.
        </Typography>
      ),
    },
  },
  {
    name: 'fullName',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Full Name',
    },
  },
  {
    name: 'email',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Email',
      format: 'email',
    },
  },
  {
    name: 'phoneNumber',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Phone Number',
      format: 'phone',
    },
  },
]

const AgentContactForm = (props: AgentContactFormProps) => {
  const { open, onClose, exampleOfferPageUrl } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [createBroker] = useMutation(LandingCreateBrokerDocument)
  const { refetch: searchBrokers } = useQuery(LandingSearchBrokersDocument, {
    skip: true,
  })

  const theme = useTheme()

  const { track } = useTracking()

  const onOfferPageContactInfo = () => {
    track('agents.open-example.contact-info', {
      category: 'agents',
      actionType: UserEventType.BUTTON_CLICK,
    })
    let url = `${exampleOfferPageUrl}/?agentSample=true`
    if (exampleOfferPageUrl!.charAt(exampleOfferPageUrl!.length - 1) === '/') {
      url = `${exampleOfferPageUrl}/?agentSample=true`
    }
    window.location.href = url
  }

  const onSubmit = async (formData: FormData) => {
    const { fullName, email, phoneNumber } = formData
    setLoading(true)
    try {
      const searchBrokersResult = await searchBrokers({
        input: {
          searchString: email,
        },
      })

      const { data } = searchBrokersResult
      const matchingBrokers = data?.searchBrokers?.brokers || []
      if (!matchingBrokers.length) {
        const brokerData = {
          phoneNumber,
          fullName,
          email,
        }
        await createBrokerHelper(brokerData, createBroker)
      }

      onOfferPageContactInfo()
    } catch (e) {
      onOfferPageContactInfo()
    }
    setLoading(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '500px',
          backgroundColor: '#ffffff',
          borderRadius: '24px',
          boxShadow: '0px 8px 24px 0px #45494D14',
          p: '32px',
          pb: 0,

          [theme.breakpoints.down('md')]: {
            top: 0,
            left: 0,
            maxHeight: 'unset',
            height: 'calc(100% - 32px)',
            width: 'calc(100% - 64px)',
            transform: 'unset',
            borderRadius: 'unset',
            pb: '32px',
          },
        }}
      >
        <TrackedIconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '32px', right: '32px' }}
        >
          <CloseIcon />
        </TrackedIconButton>
        <GridForm
          onSubmit={onSubmit}
          inputConfigs={inputConfigs}
          loading={loading}
          gridProps={{
            spacing: 3,
          }}
          ctaBoxProps={{
            pb: '32px',
          }}
        />
      </Box>
    </Modal>
  )
}

AgentContactForm.defaultProps = {
  exampleOfferPageUrl:
    'https://flockhomes.com/property-estimate/1d532270-270f-4230-8c37-5a5fa16d3cf9?agentSample=true',
}

export default AgentContactForm
