import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Step1Image from '../../images/identify-house.webp'
import Step2Image from '../../images/phone2.webp'
import Step3Image from '../../images/money-filled.svg'
import SectionLayout from '../SharedComponents/SectionLayout'

type AgentsProcessSectionSteps = {
  step1Title?: string
  step1Description?: string
  step2Title?: string
  step2Description?: string
  step3Title?: string
  step3Description?: string
}

type SubsectionProps = {
  step: number
  title: string
  description: string
  graphic: string
  width: number
  height: number
  alt: string
}

const Subsection = (props: SubsectionProps) => {
  const { step, title, description, graphic, width, height, alt } = props

  return (
    <>
      <Grid
        item
        sm={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            component="img"
            src={graphic}
            width={width}
            height={height}
            alt={alt}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        display="flex"
        flexDirection="column"
        gap="16px"
        alignSelf="center"
      >
        <Typography variant="c1" color="moneyGreen.main">
          STEP {step}
        </Typography>
        <Typography variant="h3" color="gray8.main">
          {title}
        </Typography>
        <Typography variant="p2" color="gray8.main">
          {description}
        </Typography>
      </Grid>
    </>
  )
}

const AgentsProcessSection = (props: AgentsProcessSectionSteps) => {
  const {
    step1Title,
    step1Description,
    step2Title,
    step2Description,
    step3Title,
    step3Description,
  } = props

  return (
    <SectionLayout
      name="agents-process-section"
      backgroundColor="trustBlue.main"
    >
      <Subsection
        step={1}
        title={step1Title as string}
        description={step1Description as string}
        graphic={Step1Image}
        width={360}
        height={162}
        alt="How Flock works"
      />
      <Subsection
        step={2}
        title={step2Title as string}
        description={step2Description as string}
        graphic={Step2Image}
        width={180}
        height={363}
        alt="Flock Offer Page"
      />
      <Subsection
        step={3}
        title={step3Title as string}
        description={step3Description as string}
        graphic={Step3Image}
        width={200}
        height={190}
        alt="Flock Offer Page"
      />
    </SectionLayout>
  )
}

export default AgentsProcessSection

AgentsProcessSection.defaultProps = {
  step1Title: 'Identify a property and sign an agreement with Flock.',
  step1Description: `The agreement guarantees that you earn your commission if Flock acquires your client's property. Flock acquires single family homes and multi-family properties (up to 4 units).`,
  step2Title: 'We create an offer for your client in less than 24 hours.',
  step2Description: `Let us know a few details about the property and we'll put together an offer. You know your clients best — we'll put together the materials or let you handle the whole process on your own.`,
  step3Title: 'Flock handles the close and you receive commission.',
  step3Description:
    'You can be entirely hands-on or let Flock handle the close. We typically close within 2 weeks.',
}
